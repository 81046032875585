$(document).ready(function() {

	/************************************************/
	/**** INTERACTIVE PRICING PLAN CARDS ****/ 
	/************************************************/
	if(document.body.contains(document.querySelector('.interactive-pricing-plans'))) {
		let plansBlock = document.querySelector('.interactive-pricing-plans');

		// Select element
		const ippSelectElement = plansBlock.querySelector("#ipp-custom-select");

		// Custom dropdown UI (proxy for select element on desktop)
		const ippCustomDropdown = plansBlock.querySelector('#ipp-custom-select-proxy');
		const ippCustomDropdownSelected = plansBlock.querySelector('#ipp-custom-select-proxy-selected');
		const ippCustomDropdownOptions = plansBlock.querySelector('#ipp-custom-select-proxy-options');
		const ippCustomDropdownOptionsArray = ippCustomDropdownOptions.querySelectorAll('.custom-select-proxy__option');

		ippCustomDropdownSelected.addEventListener('click', function() {
			ippCustomDropdownOptions.style.display = ippCustomDropdownOptions.style.display === 'block' ? 'none' : 'block';
		});

		ippCustomDropdownOptionsArray.forEach(function(option) {
			option.addEventListener('click', function() {
				const value = option.getAttribute('data-value');
				ippSelectElement.value = value;
				ippCustomDropdownSelected.innerText = option.innerText;
				ippCustomDropdownOptions.style.display = 'none';

				// Trigger the change event on the selectElement
				const event = new Event('change');
				ippSelectElement.dispatchEvent(event);
			});
		});

		document.addEventListener('click', function(event) {
			if (!ippCustomDropdown.contains(event.target)) {
				ippCustomDropdownOptions.style.display = 'none';
			}
		});


		const cardsContainer = plansBlock.querySelector(".interactive-pricing-plans__cards");
		const classicCard = plansBlock.querySelector(".plan-card--classic");
		const advancedCard = plansBlock.querySelector(".plan-card--advanced");
		const plusCard = plansBlock.querySelector(".plan-card--plus");
		const plusPlan = plansBlock.querySelector(".interactive-pricing-plans__plus-plan");

		const ordersFeedback = plansBlock.querySelector("#js-orders-feedback");
		const priceFeedback = plansBlock.querySelector("#js-price");

		let currentNumber = 1000;

		// When select changes
		ippSelectElement.addEventListener('change', function() {
			currentNumber = ippSelectElement.value;

			// Set the ordersFeedback span to currentNumber
			ordersFeedback.textContent = Number(currentNumber).toLocaleString();

			// Update price per month in Classic card
			if (currentNumber == 1000 ) {
				priceFeedback.textContent = '$199';
			}
			else if (currentNumber == 1500 ) {
				priceFeedback.textContent = '$249';
			}
			else if (currentNumber == 2000) {
				priceFeedback.textContent = '$299';
			}
			else if ( currentNumber == 2500) {
				priceFeedback.textContent = '$349';
			}
			else if (currentNumber == 3000) {
				priceFeedback.textContent = '$399';
			}
			else if (currentNumber == 3500) {
				priceFeedback.textContent = '$449';
			}
			else if (currentNumber == 4000) {
				priceFeedback.textContent = '$499';
			}
			else if (currentNumber == 4500) {
				priceFeedback.textContent = '$549';
			}
			else if (currentNumber == 5000) {
				priceFeedback.textContent = '$599';
			}
			else if (currentNumber == 5500) {
				priceFeedback.textContent = '$624';
			}
			else if (currentNumber == 6000) {
				priceFeedback.textContent = '$649';
			}
			else if (currentNumber == 6500) {
				priceFeedback.textContent = '$674';
			}
			else if (currentNumber == 7000) {
				priceFeedback.textContent = '$699';
			}
			else if (currentNumber == 7500) {
				priceFeedback.textContent = '$724';
			}
			else if(currentNumber == 8000 ) {
				priceFeedback.textContent = '$749';
			}
			else if( currentNumber == 8500) {
				priceFeedback.textContent = '$774';
			}
			else if(currentNumber == 9000 ) {
				priceFeedback.textContent = '$799';
			}
			else if( currentNumber == 9500) {
				priceFeedback.textContent = '$824';
			}

			// Update recommended plan
			if (currentNumber < 2000 ) {
				classicCard.classList.add('plan-card--recommended');

				plusCard.classList.remove('plan-card--recommended');
				advancedCard.classList.remove('plan-card--recommended');
			}
			else if (currentNumber < 4000 ) {
				advancedCard.classList.add('plan-card--recommended');

				classicCard.classList.remove('plan-card--recommended');
				plusCard.classList.remove('plan-card--recommended');

			} else {
				plusCard.classList.add('plan-card--recommended');

				advancedCard.classList.remove('plan-card--recommended');
				classicCard.classList.remove('plan-card--recommended');
			}

			// Swap to full-width Plus Plan card if orders are 10,000
			if (currentNumber == 10000 ){
				cardsContainer.classList.add('hidden');
				plusPlan.classList.remove('hidden');
			} else {
				cardsContainer.classList.remove('hidden');
				plusPlan.classList.add('hidden');
			}

			// Update custom dropdown to reflect the selected value (in case the change to selected value originated from mobile select)
			ippCustomDropdownOptionsArray.forEach(function(option) {
				if (option.getAttribute('data-value') === currentNumber) {
					ippCustomDropdownSelected.innerText = option.innerText;
				}
			});
		});


	}

	/************************************************/
	/**** PRICING OVERVIEW ORDER VOLUME SWITCH ****/
	/************************************************/
	if (document.body.contains(document.querySelector('.pricing-overview-2'))) {
		let pricingOverview = document.querySelector('.pricing-overview-2');

		// Select element
		const selectElement = pricingOverview.querySelector("#custom-select");

		// Custom dropdown UI (proxy for select element on desktop)
		const customDropdown = pricingOverview.querySelector('#custom-select-proxy');
		const customDropdownSelected = pricingOverview.querySelector('#custom-select-proxy-selected');
		const customDropdownOptions = pricingOverview.querySelector('#custom-select-proxy-options');
		const customDropdownOptionsArray = customDropdownOptions.querySelectorAll('.custom-select-proxy__option');

		customDropdownSelected.addEventListener('click', function() {
			customDropdownOptions.style.display = customDropdownOptions.style.display === 'block' ? 'none' : 'block';
		});

		customDropdownOptionsArray.forEach(function(option) {
			option.addEventListener('click', function() {
				const value = option.getAttribute('data-value');
				selectElement.value = value;
				customDropdownSelected.innerText = option.innerText;
				customDropdownOptions.style.display = 'none';

				// Trigger the change event on the selectElement
				const event = new Event('change');
				selectElement.dispatchEvent(event);
			});
		});

		document.addEventListener('click', function(event) {
			if (!customDropdown.contains(event.target)) {
				customDropdownOptions.style.display = 'none';
			}
		});

		// Plan column header elements
		const classicPriceFigure = pricingOverview.querySelector("#js-monthly-price");
		const classicPriceContainer = pricingOverview.querySelector("#js-classic-price-container");
		const classicPricePlaceholder = pricingOverview.querySelector("#js-classic-price-placeholder");

		// Orders <-> prices map
		const priceMapping = {
			initial: '$199',
			1000: '$199',
			1500: '$249',
			2000: '$299',
			2500: '$349',
			3000: '$399',
			3500: '$449',
			4000: '$499',
			5000: '$599',
			6000: '$649',
			7000: '$699',
			8000: '$749',
			9000: '$799',
			10000: 'n/a'
		};

		// When select changes
		selectElement.addEventListener('change', function() {
			const selectedValue = selectElement.value;

			// Update price
			if (selectedValue == 10000) {
				classicPriceContainer.classList.add('hidden');
				classicPricePlaceholder.classList.remove('hidden');
			} else {
				classicPriceContainer.classList.remove('hidden');
				classicPricePlaceholder.classList.add('hidden');
				classicPriceFigure.textContent = priceMapping[selectedValue] || '';
			}

			// Update custom dropdown to reflect the selected value (in case the change to selected value originated from mobile select)
			customDropdownOptionsArray.forEach(function(option) {
				if (option.getAttribute('data-value') === selectedValue) {
					customDropdownSelected.innerText = option.innerText;
				}
			});
		});
	}


	/************************************************/
	/**** PRICING PLANS OVERVIEW ****/
	/************************************************/
	document.querySelectorAll('.pricing-overview').forEach(function(pricingOverview) {
		const expandFeaturesTriggers = pricingOverview.querySelectorAll('.pricing-overview__features-accordion-trigger');

		expandFeaturesTriggers.forEach(function(trigger) {
			trigger.addEventListener('click', function() {
				const targetId = trigger.getAttribute('data-target');
				const featuresList = pricingOverview.querySelector('#' + targetId);

				trigger.classList.toggle('expanded');
				featuresList.classList.toggle('expanded');
			});
		});
	});


	/************************************************/
	/**** COMPARISON TABLE ****/
	/************************************************/

	//// tab click function on pricing comparison table
	$('.compare-tabs-selector').click(function() {
		event.preventDefault(); // Prevent default link behavior

		// Remove 'is-active' class from all siblings
		$('.compare-tabs-selector').removeClass('is-active');

		// Add 'is-active' class to the clicked element
		$(this).addClass('is-active');

		// get target panel ID from url
		var panelId = $(this).attr('href');

		// show panel
		$(panelId).show();

		// hide other panels
		$('.js-pricing-compare-panel').not(panelId).hide();

	})
	

	$('tr.heading').click(function() {
		if ($(this).hasClass('active')) {
			$(this).removeClass('active')
		} else {
			$(this).addClass('active')
		}
		$(this)
		.closest('tbody')
		.find('tr.list')
		.toggle()
	})

})
