/*
* Set a css variable value which determines where the timeline vertical bar should terminate
*/
function updateTimelineLine() {
	
	document.querySelectorAll('.timeline').forEach(timelineBlock => {
		const timelineLine = timelineBlock.querySelector('.timeline__list');
		const lastCard = timelineBlock.querySelector('.timeline__item:last-of-type');
		const lastCardHeight = lastCard.getBoundingClientRect().height;

		timelineLine.style.setProperty('--timeline-line-bottom', `${lastCardHeight - 5}px`);
	});
}

document.addEventListener('DOMContentLoaded', function () {
	if (document.querySelector('.timeline')) {

		updateTimelineLine();

		// Add a listener for window resize to recalculate the timeline height
		window.addEventListener('resize', function () {
			updateTimelineLine();
		});

	}
});
